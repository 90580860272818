// Do not use any shared modules here (i.e. lodash-es).
// The env is imported by a separate entry point (datadog.ts).
// See Shared module is not available for eager consumption

export interface IGlobalEnv {
  // system env variables
  COMMIT: string

  // manual env variables
  NODE_ENV: string
  APP_BASE_URL: string
  API_BASE_URL: string
  APP_ENV: string
  BILL_OF_LADING_ENDPOINT: string
  CARGO_TYPE_ENDPOINT: string
  DATADOG_RUM_SITE: string
  DATADOG_RUM_SERVICE: string
  DATADOG_RUM_ENABLED: boolean | string
  DATADOG_RUM_SESSION_REPLAY_ENABLED: boolean | string
  DATADOG_APP_ID: string
  DATADOG_CLIENT_TOKEN: string
  SHIPPING_ENDPOINT: string
  SHIPMENT_HEALTH_ENDPOINT: string
  SHIPMENTS_V2_ENDPOINT: string
  SHIP_FRONTEND_ENDPOINT: string
  USER_SERVICE_ENDPOINT: string
  WEBSOCKET_ENDPOINT: string
  AUTH0_DOMAIN: string
  AUTH0_AUDIENCE: string
  AUTH0_CLIENT_ID: string
  KEYCLOAK_CLIENT_ID: string
  KEYCLOAK_EU_URL: string
  EASYBILL_PREFIX: string
  SENTRY_DSN: string
  FULLSTORY_ANALYTICS_ID: string
  GOOGLE_API_KEY: string
  OCEAN_INSIGHTS_URL: string
  ORIGIN_ENDPOINT: string
  FIREBASE_PROJECT_ID: string
  FIREBASE_API_KEY: string
  TALK_JS_ID: string
  TALK_JS_ENCRYPTION_KEY: string
  BOOKINGS_V2_ENDPOINT: string
  DOCUMENTS_ENDPOINT: string
  DOCUMENT_CAPTURE_ENDPOINT: string
  POEDITOR_EXTERNAL: string
  PARTNER_BOOKING_ENDPOINT: string
  CUSTOM_STATUS_ENDPOINT: string
  CONTRACT_MANAGEMENT_ENDPOINT: string
  PARTNER_MASTER_DATA_ENDPOINT: string
  TRANSPORT_NETWORK_ENDPOINT: string
  DEM_DET_CORE_ENDPOINT: string
  TRADE_LANES_ENDPOINT: string
  TRANSL8_ENDPOINT: string
  WORKBENCH_ENDPOINT: string
  AUDITLOG_ENDPOINT: string
  BILLING_ENDPOINT: string
  MACRO_VIEW_ENDPOINT: string
  SCHEDULES_ENDPOINT: string
  RATES_MANAGEMENT_ENDPOINT: string
  BOOKING_REQUESTS_ENDPOINT: string
  QUOTATION_ENDPOINT: string
  FEED_V2_ENDPOINT: string
  NOTIFICATION_CENTER_ENDPOINT: string
  TRANSPORT_PLAN_ENDPOINT: string
  FINANCE_ENDPOINT: string
  REVENUE_ENDPOINT: string
  COSTS_ENDPOINT: string
  INVOICING_ENDPOINT: string
  CUSTOMER_MASTER_DATA_ENDPOINT: string
  INTERCOM_APP_ID?: string
  WORKBENCH_V2_CUTOFF_DATE: string
  SUBSCRIPTIONS_ENDPOINT: string
  SUBSCRIPTIONS_ORCHESTRATION_ENDPOINT: string
  SHIPMENTS_ENDPOINT: string
  TRANSPORT_ORDER_ENDPOINT: string
  FOREIGN_EXCHANGE_RATE_ENDPOINT: string
  WORKBENCH_SEA_FCL_CUTOFF_DATE: string
  WORKBENCH_AIR_CUTOFF_DATE: string
  WORKBENCH_SEA_LCL_RAIL_CUTOFF_DATE: string
  CATALOG_ENDPOINT: string
}

export interface IEnv extends IGlobalEnv {
  SHIPPING_ENDPOINT_V1: string
  SHIPPING_ENDPOINT_V2: string
  DATADOG_RUM_ENABLED: boolean
  DATADOG_RUM_SESSION_REPLAY_ENABLED: boolean
  OCEAN_INSIGHTS_URL: string

  TALK_JS_ID: string
  TALK_JS_ENCRYPTION_KEY: string
}

const ENV = window.env

export const env: IEnv = {
  COMMIT: ENV.COMMIT,

  NODE_ENV: ENV.NODE_ENV,
  APP_BASE_URL: ENV.APP_BASE_URL || '',
  API_BASE_URL: ENV.API_BASE_URL || '',
  APP_ENV: ENV.APP_ENV,
  AUTH0_DOMAIN: ENV.AUTH0_DOMAIN,
  AUTH0_AUDIENCE: ENV.AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID: ENV.AUTH0_CLIENT_ID,
  KEYCLOAK_CLIENT_ID: ENV.KEYCLOAK_CLIENT_ID,
  KEYCLOAK_EU_URL: ENV.KEYCLOAK_EU_URL,
  EASYBILL_PREFIX: ENV.EASYBILL_PREFIX,
  SENTRY_DSN: ENV.SENTRY_DSN,
  WEBSOCKET_ENDPOINT: ENV.WEBSOCKET_ENDPOINT,
  FULLSTORY_ANALYTICS_ID: ENV.FULLSTORY_ANALYTICS_ID,
  GOOGLE_API_KEY: ENV.GOOGLE_API_KEY,

  DATADOG_RUM_SITE: ENV.DATADOG_RUM_SITE,
  DATADOG_RUM_SERVICE: ENV.DATADOG_RUM_SERVICE,
  DATADOG_RUM_ENABLED: ENV.DATADOG_RUM_ENABLED === 'true',
  DATADOG_RUM_SESSION_REPLAY_ENABLED: ENV.DATADOG_RUM_SESSION_REPLAY_ENABLED === 'true',
  DATADOG_APP_ID: ENV.DATADOG_APP_ID,
  DATADOG_CLIENT_TOKEN: ENV.DATADOG_CLIENT_TOKEN,
  BILL_OF_LADING_ENDPOINT: `${ENV.BILL_OF_LADING_ENDPOINT}/`,
  SHIPMENT_HEALTH_ENDPOINT: `${ENV.SHIPMENT_HEALTH_ENDPOINT}/`,
  SHIPMENTS_V2_ENDPOINT: `${ENV.SHIPMENTS_V2_ENDPOINT}/`,
  CARGO_TYPE_ENDPOINT: `${ENV.CARGO_TYPE_ENDPOINT}/`,
  SHIP_FRONTEND_ENDPOINT: `${ENV.SHIP_FRONTEND_ENDPOINT}/`,
  USER_SERVICE_ENDPOINT: ENV.USER_SERVICE_ENDPOINT,
  SHIPPING_ENDPOINT: ENV.SHIPPING_ENDPOINT,
  SHIPPING_ENDPOINT_V1: `${ENV.SHIPPING_ENDPOINT}/api/v1/tms/`,
  SHIPPING_ENDPOINT_V2: `${ENV.SHIPPING_ENDPOINT}/api/v2/tms/`,
  OCEAN_INSIGHTS_URL: ENV.OCEAN_INSIGHTS_URL,
  ORIGIN_ENDPOINT: ENV.ORIGIN_ENDPOINT,
  FIREBASE_PROJECT_ID: ENV.FIREBASE_PROJECT_ID,
  FIREBASE_API_KEY: ENV.FIREBASE_API_KEY,
  TALK_JS_ID: ENV.TALK_JS_ID,
  TALK_JS_ENCRYPTION_KEY: ENV.TALK_JS_ENCRYPTION_KEY,
  BOOKINGS_V2_ENDPOINT: ENV.BOOKINGS_V2_ENDPOINT,
  DOCUMENTS_ENDPOINT: ENV.DOCUMENTS_ENDPOINT,
  DOCUMENT_CAPTURE_ENDPOINT: ENV.DOCUMENT_CAPTURE_ENDPOINT,
  POEDITOR_EXTERNAL: ENV.POEDITOR_EXTERNAL,
  PARTNER_BOOKING_ENDPOINT: `${ENV.PARTNER_BOOKING_ENDPOINT}/`,
  CUSTOM_STATUS_ENDPOINT: `${ENV.CUSTOM_STATUS_ENDPOINT}/`,
  CONTRACT_MANAGEMENT_ENDPOINT: ENV.CONTRACT_MANAGEMENT_ENDPOINT,
  PARTNER_MASTER_DATA_ENDPOINT: ENV.PARTNER_MASTER_DATA_ENDPOINT,
  TRANSPORT_NETWORK_ENDPOINT: `${ENV.TRANSPORT_NETWORK_ENDPOINT}/`,
  DEM_DET_CORE_ENDPOINT: `${ENV.DEM_DET_CORE_ENDPOINT}/`,
  TRADE_LANES_ENDPOINT: `${ENV.TRADE_LANES_ENDPOINT}/`,
  TRANSL8_ENDPOINT: `${ENV.TRANSL8_ENDPOINT}/`,
  WORKBENCH_ENDPOINT: ENV.WORKBENCH_ENDPOINT,
  AUDITLOG_ENDPOINT: ENV.AUDITLOG_ENDPOINT,
  BILLING_ENDPOINT: ENV.BILLING_ENDPOINT,
  MACRO_VIEW_ENDPOINT: ENV.MACRO_VIEW_ENDPOINT,
  SCHEDULES_ENDPOINT: ENV.SCHEDULES_ENDPOINT,
  RATES_MANAGEMENT_ENDPOINT: ENV.RATES_MANAGEMENT_ENDPOINT,
  BOOKING_REQUESTS_ENDPOINT: ENV.BOOKING_REQUESTS_ENDPOINT,
  QUOTATION_ENDPOINT: ENV.QUOTATION_ENDPOINT,
  FEED_V2_ENDPOINT: ENV.FEED_V2_ENDPOINT,
  NOTIFICATION_CENTER_ENDPOINT: ENV.NOTIFICATION_CENTER_ENDPOINT,
  TRANSPORT_PLAN_ENDPOINT: ENV.TRANSPORT_PLAN_ENDPOINT,
  FINANCE_ENDPOINT: ENV.FINANCE_ENDPOINT,
  REVENUE_ENDPOINT: ENV.REVENUE_ENDPOINT,
  COSTS_ENDPOINT: ENV.COSTS_ENDPOINT,
  INVOICING_ENDPOINT: ENV.INVOICING_ENDPOINT,
  CUSTOMER_MASTER_DATA_ENDPOINT: ENV.CUSTOMER_MASTER_DATA_ENDPOINT,
  INTERCOM_APP_ID: ENV.INTERCOM_APP_ID,
  WORKBENCH_V2_CUTOFF_DATE: ENV.WORKBENCH_V2_CUTOFF_DATE,
  SUBSCRIPTIONS_ENDPOINT: ENV.SUBSCRIPTIONS_ENDPOINT,
  SUBSCRIPTIONS_ORCHESTRATION_ENDPOINT: ENV.SUBSCRIPTIONS_ORCHESTRATION_ENDPOINT,
  SHIPMENTS_ENDPOINT: ENV.SHIPMENTS_ENDPOINT,
  TRANSPORT_ORDER_ENDPOINT: `${ENV.TRANSPORT_ORDER_ENDPOINT}/`,
  FOREIGN_EXCHANGE_RATE_ENDPOINT: ENV.FOREIGN_EXCHANGE_RATE_ENDPOINT,
  WORKBENCH_SEA_FCL_CUTOFF_DATE: ENV.WORKBENCH_SEA_FCL_CUTOFF_DATE,
  WORKBENCH_AIR_CUTOFF_DATE: ENV.WORKBENCH_AIR_CUTOFF_DATE,
  WORKBENCH_SEA_LCL_RAIL_CUTOFF_DATE: ENV.WORKBENCH_SEA_LCL_RAIL_CUTOFF_DATE,
  CATALOG_ENDPOINT: ENV.CATALOG_ENDPOINT,
}
